/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import { useField, useFormikContext } from 'formik'

import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import { DateRangePicker } from 'react-date-range'

import { formatDateRange } from '../../utils'

const _Form = (props) => {
    const { handleReset, handleSubmit } = useFormikContext()
    return (
        <Form onReset={handleReset} onSubmit={handleSubmit} {...props} />
    )
}

export { _Form as Form }

export const Field = ({ type, name, label, placeholder, options }) => {
    const [field, meta] = useField({ name, type })

    return (
        <Form.Group>
            { label && <Label>{ label }</Label> }
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={meta.value}
                invalid={!!meta.error}
                { ...field }
                >
                { options && options.map(([ value, label ], i) => (
                    <option key={'option'+i} value={value}>{ label }</option>
                ))}
            </Form.Control>
            <Form.Control.Feedback>{ meta.error }</Form.Control.Feedback>
        </Form.Group>
    )
}

export const Select = ({ items, renderItem, onSelect, allowNone, ...rest }) => (
    <Form.Select type="select" onChange={ev => onSelect(ev.target.value)} { ...rest }>
        { allowNone && <option value="">-</option> }
        { items.data && items.data.data.map(item => (
            <option key={'item'+item.id} value={item.id}>{ renderItem(item) }</option>
        ))}
    </Form.Select>
)

export const DateRangeWidget = ({ value, setValue }) => {
    const [open, setOpen] = React.useState(false)

    return (
        <Dropdown>
            <Dropdown.Toggle className="form-control-colors">
                <i className="fas fa-calendar"/>&nbsp;{ formatDateRange(value[0], value[1])}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="m-2">
                    <DateRangePicker
                        ranges={[{startDate: value[0], endDate: value[1], key: 'selection'}]}
                        onChange={({ selection }) => {
                            setValue([selection.startDate, selection.endDate])
                        }}
                        />
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}
