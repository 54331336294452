/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from 'i18n'
import React from 'react'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import post from '../../utils/post'
import { Form, Field } from '../widgets/Form'

const LoginPage = () => (
    <div className="LoginPage">
        <Card className="text-center">
            <Card.Body>
                <Card.Title tag="h2">{ i18n.t('Login') }</Card.Title>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    validate={values => {
                        const errors = {}
                        if (!values.username) {
                            errors.username = i18n.t('Please enter username address')
                        }
                        if (!values.password) {
                            errors.password = i18n.t('Please enter password')
                        }
                        return errors
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        setSubmitting(true)

                        post({ path: '/user/login', params: values }).then(() => {
                            let query = new URLSearchParams(window.location.search)
                            window.location = query.get('next') || '/app'
                        }).catch(() => {
                            setFieldError('username', i18n.t('Unknown username / password'))
                            setSubmitting(false)
                        })
                    }}
                    >
                    {({ isSubmitting }) => (
                        <Form>
                            <Field type="text" name="username" placeholder={i18n.t('Enter Username')} />
                            <Field type="password" name="password" placeholder={i18n.t('Enter Password')} />
                            <Button type="submit" color="primary" disabled={isSubmitting}>{i18n.t('Sign in')}</Button>
                            <Card.Text><Link to="/reset-password"><small>{ i18n.t('Forgot Password?') }</small></Link></Card.Text>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    </div>
)

export default LoginPage
