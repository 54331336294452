/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import fetch from 'cross-fetch'

function getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    if (match) {
        return match[2]
    }
}

let postQueue = []
let fetching = false
let failCount = 0

function nextAction() {
    let action = postQueue[0]
    if (action) {
        fetching = true

        console.log('POST', action.path, action.params)

        fetch('/api/app'+action.path, {
            method: 'POST',
            body: JSON.stringify(action.params),
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            credentials: 'same-origin',
            cache: 'no-cache',
            redirect: 'follow',
        }).then(resp => {
            if (resp.status == 403) {
                throw new Error('Permission denied')
            } else if (resp.status != 200) {
                throw new Error('Bad response: '+resp.status)
            }
            return resp.json()
        }).then(resp => {
            if (resp.error) {
                throw resp
            }
            action.requiredFields.map(field => {
                if (resp[field] === undefined) {
                    throw new Error('Server resp field missing: '+field)
                }
            })
            return resp
        }).then(resp => {
            failCount = 0
            if (!action.isCancelled) {
                action.resolve(resp)
            }
        }).catch(err => {
            console.warn('POST', action.path, err)
            if (err.error) {
                failCount = 0
            } else {
                failCount++
            }
            if (!action.isCancelled) {
                action.reject(err)
            }
        }).finally(() => {
            postQueue.shift()

            if (failCount >= 3) {
                console.log('Server not responding')
                setTimeout(nextAction, 30000)
            } else {
                nextAction()
            }
        })

    } else {
        fetching = false
    }
}

export function cancelPosts(component) {
    postQueue.map((action) => {
        if (action.component == component) {
            action.isCancelled = true
        }
    })
}

export default function({ path, params, requiredFields = [], component = null }) {
    params = params || {}

    let action = { path, params, requiredFields, component }
    let promise = new Promise((resolve, reject) => { Object.assign(action, { resolve, reject }) })

    postQueue.push(action)
    if (!fetching) {
        fetching = true
        setTimeout(nextAction, 0)
    }

    return promise
}
