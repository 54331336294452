/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from 'i18n'
import React from 'react'

const Footer = ({ version }) => {
    return (
        <div className="Footer">
            { i18n.t('Version') } { version }&nbsp;
            <span style={{ flex: '1 1' }}>{ i18n.t('Copyright 2020, All Rights Reserved') }</span>
        </div>
    )
}

export default Footer
