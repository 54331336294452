/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

window.SERVER_VARS = JSON.parse(document.getElementById('server-vars').textContent)

require('expose-loader?exposes=Components!./components/Login')
