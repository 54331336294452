/**
 * Usage:
 * import i18n from 'i18n'
 *
 * then call translate like this:
 * i18n.t('hello.world', 'Fallback')
 *
 * See: https://www.i18next.com/ for more information
 */

import i18next from 'i18next';

import en from './translations/en.json';
import es from './translations/es.json';

i18next.init({
    languages: ['en', 'es'],
    fallbackLng: ['en'],
    resources: {
        en: { translation: en },
        en: { translation: es },
    }
});

i18next.changeLanguage(SERVER_VARS.language);

export default i18next;