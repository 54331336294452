/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'

import { format } from 'date-fns'

export const useTimer = ({ interval=1000 }) => {
    const [now, setNow] = React.useState(new Date().getTime());

    React.useEffect(() => {
        const timeout = setInterval(() => setNow(new Date().getTime()), interval)
        return () => clearInterval(timeout)
    }, [interval]);

    return now
}

export const formatPrice = (x) => {
    const val = Number.parseFloat(x)
    return Number.isNaN(val) ? '' : val.toFixed(2);
}

export const formatDate = (x) => (
    x ? new Date(x).toDateString() : ''
)

export const formatDateRange = (start, end) => {
    if (!start || !end) {
        return ''
    }

    const now = new Date()
    const isCurrYear = start.getYear() == now.getYear() && end.getYear() == now.getYear()
    const yearFmt = isCurrYear ? '' : ', y'

    let s
    if (start.getYear() == end.getYear()) {
        if (start.getMonth() == end.getMonth()) {
            if (start.getDate() == end.getDate()) {
                s = format(start, 'MMM d'+yearFmt)
            } else {
                s = format(start, 'MMM d - ') + format(end, 'd'+yearFmt)
            }
        } else {
            s = format(start, 'MMM d - ') + format(end, 'MMM d'+yearFmt)
        }
    } else {
        s = format(start, 'MMM d'+yearFmt) + format(end, ' - MMM d'+yearFmt)
    }

    return s
}
