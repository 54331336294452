/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import LoginPage from '../pages/LoginPage'

const PulicRoutes = () => (
    <Switch>
        <Route path={'/'} exact component={LoginPage} />

        <Route><Redirect to="/" /></Route>
    </Switch>
)

export default PulicRoutes
