/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */


import { BrowserRouter as Router } from 'react-router-dom'

import AppNavBase from './widgets/AppNavBase'
import Footer from './widgets/Footer'
import PulicRoutes from './routes/PublicRoutes'

const App = ({ footerProps }) => (
    <Router basename="/login">
        <div className="App">
            <AppNavBase />
            <div className="Body">
                <PulicRoutes />
            </div>
            <Footer { ...footerProps }/>
        </div>
    </Router>
)

export { App }
