/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2020 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import Navbar from 'react-bootstrap/Navbar'

const AppNavBase = ({ children }) => (
    <div className="AppNav">
        <Navbar bg="light" expand="md">
            <Navbar.Brand href="/">
                <img className="navLogo" src={'/static/frontend/img/favicon.ico'}/>
            </Navbar.Brand>
            { children }
        </Navbar>
    </div>
)

export default AppNavBase
